@import " ~react-perfect-scrollbar/dist/css/styles.css";


.ql-container {
    min-height: 300px;
}

.ql-snow .ql-tooltip {
    transform: translate(150px) !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}